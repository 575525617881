import React, { useContext, useState, useEffect } from "react";
import CartContext from "../../context/cart-context";
import { Link } from "gatsby";

function CartAddToCart(props) {
  const cartContext = useContext(CartContext);
  const [quantity, setQuantity] = useState(
    cartContext.totals.countOfCoversInCart
  );

  useEffect(() => {
    if (props.product.node.slug === "rush-order") {
      setQuantity(1);
    } else if (!quantity) {
      setQuantity(
        props.allowAddWithNoCovers ? 1 : cartContext.totals.countOfCoversInCart
      );
    }
  }, [
    cartContext.totals.countOfCoversInCart,
    quantity,
    props.allowAddWithNoCovers,
    props.product.node.slug,
  ]);

  return (
    <div>
      {(props.product.node.product.type === "simple" &&
        +cartContext.totals.countOfCoversInCart) ||
      props.allowAddWithNoCovers ? (
        <div className="flex flex-wrap">
          <label
            className={`${
              props.product.node.slug === "rush-order"
                ? "hidden"
                : "mr-2 md:mr-4"
            }`}
            aria-label="Quantity"
          >
            <select
              name="quantity"
              className={
                "bg-white shadow appearance-none border border-grey-light rounded md:ml-2 md:py-2 md:px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
              }
              onBlur={(e) => {
                setQuantity(+e.currentTarget.value);
              }}
              defaultValue={
                props.allowAddWithNoCovers
                  ? 1
                  : cartContext.totals.countOfCoversInCart
              }
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
            </select>
          </label>
          <button
            aria-label="Add to cart"
            onClick={() => {
              if (props.notify) {
                props.notify(`Added ${props.product.node.title} to cart`);
              }
              return cartContext.addItemToCart(
                {
                  id: props.product.node.product.sku,
                  taxable: props.product.node.product.taxable,
                  title: props.product.node.title,
                  category: props.product.node.product.category,
                  basePrice: +props.product.node.product.basePrice,
                  salePrice: +props.product.node.product.salePrice,
                  attributes: [],
                  quantity: +quantity,
                  url: `/${props.product.node.slug}/`,
                  image:
                    props.product.node.featuredImage.node.localFile.publicURL,
                },
                false
              );
            }}
            className={
              props.buttonClasses
                ? props.buttonClasses
                : `block md:inline-block bg-grey md:bg-white text-white md:text-${process.env.THEME_COLOR_PRIMARY}-darker text-sm px-4 py-3 mb-8 no-underline rounded uppercase`
            }
          >
            Add To Cart
          </button>
        </div>
      ) : (
        <Link
          to={`/${props.product.node.slug}/`}
          className={`inline-block bg-grey md:bg-white text-white md:text-${process.env.THEME_COLOR_PRIMARY}-darker text-sm px-4 py-3 no-underline rounded uppercase`}
        >
          Learn More
        </Link>
      )}
    </div>
  );
}
export default CartAddToCart;
